<template>
	<div class="JDeviceList">

		<div v-if="isLogin && tempList && tempList.length > 0" style="position:relative;height:auto;margin-top:10px;">
			<table class="configItemTalbe" width="99%">
				<thead>
					<td>{{'Name'|i18n}}</td>
					<td>{{'设备类型'}}</td>
					<td>{{'设备类型码'}}</td>
					<td>{{'服务类型'}}</td>
					<td>{{'服务类型码'}}</td>
					<td>{{'UI模板'}}</td>
					<td>{{'dataQryName'|i18n}}</td>
					<td>{{'dataQryDefId'|i18n}}</td>
					<!-- <td>{{'desc'|i18n}}</td> -->
					<td>{{'status'|i18n}}</td>
					<!-- <td>{{'deviceId'|i18n}}</td> -->
					<td>{{'clientId'|i18n}}</td>
					<!-- <td>{{'updatedTime'|i18n}}</td> -->
					<td>{{"Operation"|i18n}}</td>
					</tr>
				</thead>
				<tr v-for="c in tempList" :key="'ide' + c.id">
					<td>{{c.name}}</td>
					<td>{{c.boardName}}</td>
					<td>{{c.boardType}}</td>
					<td>{{getSrvTypeName(c.type)}}</td>
					 <td>{{c.type}}</td>
					<td>{{c.templateName}}</td>
					<td>{{c.dataQryName}}</td>
					<td>{{c.dataQryDefId}}</td>
					<!-- <td>{{c.desc}}</td> -->
					<td>{{c.status==1?'启用':'禁用'}}</td>
					<!-- <td>{{c.deviceId}}</td> -->
					<td>{{c.clientId}}</td>
					<!-- <td>{{c.updatedTime | formatDate(1)}}</td> -->
					<td>
						<a @click="viewDev(c)">{{"View"|i18n}}</a>&nbsp;
						<a @click="updateDev(c)">{{"Update"|i18n}}</a>&nbsp;
						<a @click="deleteDev(c.id)">{{"Delete"|i18n}}</a>&nbsp;
						<a @click="serviceList(c)">{{"Service"|i18n}}</a>&nbsp;
					</td>
				</tr>
			</table>
		</div>

		<div v-if="isLogin && tempList && tempList.length > 0" style="position:relative;text-align:center;">
			<Page ref="pager" :total="totalNum" :page-size="queryParams.size" :current="queryParams.curPage"
				show-elevator show-sizer show-total @on-change="curPageChange" @on-page-size-change="pageSizeChange"
				:page-size-opts="[10, 30, 60,100]"></Page>
		</div>

		<div v-if="!isLogin">
			No permission!
		</div>

		<div v-if="!tempList || tempList.length == 0">
			No data!
		</div>

		<!-- 弹出查询页面 -->
		<div v-if="isLogin" :style="queryDrawer.drawerBtnStyle" class="drawerJinvokeBtnStatu"
			@mouseenter="openQueryDrawer()"></div>

<!--
 <Drawer ref="asInfo"  v-model="asDrawer.drawerStatus" :closable="false" placement="right" :transfer="false"
         :styles="asDrawer.drawerBtnStyle" :draggable="true" :scrollable="true" width="50" 
		 :mask-closable="false" :mask="false">
 -->
		<Drawer ref="deviceInfoDrawer" v-model="deviceDrawer.drawerStatus" :closable="false" placement="right"
			:transfer="false" :styles="deviceDrawer.drawerBtnStyle" :draggable="true" :scrollable="true" width="40"
			:mask-closable="false" :mask="false">
			<el-row>
				<el-button size="mini" @click="deviceDrawer.drawerStatus = false">取消</el-button>
				<el-button :disabled="model==3" size="mini" type="primary" @click="doAddOrUpdateParam">保存</el-button>
			</el-row>

			<el-row>
				<el-col :span="6">{{"服务名称"}}</el-col>
				<el-col><el-input v-model="temp.name" :disabled="model==3" /></el-col>
			</el-row>
			<el-row>
				<el-col :span="6">{{"设备类型"}}</el-col>
				<el-col>
					<el-select style="width:100%" v-model="temp.boardType" :disabled="model==3" @change="selectBoardType">
						<el-option v-for="ty in boardTypes" :key="ty.attr0" :value="ty.attr0" :label="ty.name"></el-option>
					</el-select>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col :span="6">{{"服务类型"}}</el-col>
				<el-col>
					<el-select style="width:100%" v-model="temp.type" :disabled="model==3">
						<el-option v-for="ty in tempsTypesMap" :key="ty.attr0" :value="ty.attr0" :label="ty.name"></el-option>
					</el-select>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col :span="6">{{"UI模板"}}</el-col>
				<el-col>
					<el-select style="width:100%" v-model="temp.templateId" :disabled="model==3"  @change="selectTemplate">
						<el-option v-for="ty in temps" :key="ty.id" :value="ty.id" :label="ty.val"></el-option>
					</el-select>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="6">{{"所属产品"|i18n}}</el-col>
				<el-col>
					<el-select style="width:100%" v-model="selProductId" :disabled="model==3">
						<el-option key="'prd_0'" value="">请选择</el-option>
						<el-option v-for="(val,key) in prdMap" :key="'prd_'+key" :value="key" :label="val"></el-option>
					</el-select>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col :span="6">{{"ClientId"|i18n}}</el-col>
				<el-col>
					<el-select style="width:100%" v-model="temp.clientId" :disabled="model==3" placeholder="请选择">
						<el-option v-for="o in $jr.auth.getClients()" :key="'c_'+o" :value="o" :label="o"></el-option>
					</el-select>
				</el-col>
			 </el-row>
			<!-- <el-row>
				<el-col>所属设备</el-col>
				<el-col>
					<el-select style="width:100%" v-model="temp.deviceId" :disabled="model==3">
						<el-option v-for="(v,k) in allDeviceMap" :key="k" :value="k" :label="v"></el-option>
					</el-select>
				</el-col>
			</el-row> -->

			<el-row>
				<el-col>{{"状态查询接口"|i18n}}
				<el-button v-if="model!=3" type="primary" size="mini" @click="selectOp(1,temp)">先择</el-button>
					<el-button v-if="model!=3 && temp.dataQryName" type="primary" size="mini" @click="clearQryOp(1,temp)">清空</el-button>
				</el-col>
				<el-col>
					<el-input v-model="temp.dataQryName" disabled/>
				</el-col>
			</el-row>
			
			<el-row>
				<el-col>{{"查询间隔(单位秒,需大于或等于3秒,等于0表示不自动查询)"}}</el-col>
				<el-col><el-input v-model="temp.qryInterval" :disabled="model==3 || !temp.dataQryDefId" /></el-col>
			</el-row>
			
			<el-row>
				<el-col>启用</el-col>
				<el-select style="width:100%" v-model="temp.status" :disabled="model==3" placeholder="请选择">
					<el-option :key="1" :value="1" label="启用"></el-option>
					<el-option :key="0" :value="0" label="禁用"></el-option>
				</el-select>
			</el-row>
			
			<el-row>
				<el-col :span="6">{{"服务描述"}}</el-col>
				<el-col><el-input v-model="temp.desc" :disabled="model==3" /></el-col>
			</el-row>
			<el-row v-if="model!=2">
				<el-col :span="6">{{"服务ID"|i18n}}</el-col>
				<el-col><el-input v-model="temp.id" :disabled="true" /></el-col>
			</el-row>
			<el-row v-if="model!=2">
				<el-col :span="6">{{"服务账号"|i18n}}</el-col>
				<el-col><el-input v-model="temp.createdBy" :disabled="true" /></el-col>
			</el-row>
			<el-row v-if="model!=2">
				<el-col :span="6">{{"创建时间"|i18n}}</el-col>
				<el-col>{{temp.updatedTime | formatDate(2)}}</el-col>
			</el-row>
			
			<el-row v-if="model==1">
				<el-col :span="24">
					<el-button type="primary"  size="mini" @click="addParam()">增加操作</el-button>
				</el-col>
			</el-row>
			
			<table v-if="temp.ops && temp.ops.length > 0" class="configItemTalbe" width="99%">
			    <thead>
			        <td>{{'Label'|i18n}}</td><td>{{'Name'|i18n}}</td>
					<td>{{'opId'|i18n}}</td>
					<td>{{'qryOpId'|i18n}}</td>
					<td>{{'qryName'|i18n}}</td>
			        <td>{{"Operation"|i18n}}</td>
			    </thead>
			    <tr v-for="c in temp.ops" :key="'h_'+c.name">
					  <td>{{c.label}}</td><td>{{c.name}}</td> <td>{{c.opId}}</td>
					  <td>{{c.qryOpId}}</td><td>{{c.qryName}}</td>
			        <td>
			          <!-- <a v-if="model==1" @click="viewParam(c)">{{'View'|i18n}}</a>&nbsp; -->
			          <!-- <a v-if="model==1" @click="updateParam(c)">{{'Update'|i18n}}</a>&nbsp; -->
			           <a v-if="model==1" @click="deleteParam(c)">{{'Delete'|i18n}}</a>
					    &nbsp;<a v-if="model==1" @click="updateLabel(c)">{{'改名'}}</a>
						&nbsp;<a v-if="model==1" @click="selectOp(3,c)">{{'查询状态'}}</a>
						&nbsp;<a v-if="model==1 && c.qryOpId" @click="clearQryOp(3,c)">{{'清空查询'}}</a>
			        </td>
			    </tr>
			</table> 

		</Drawer>

		<!-- 数据查询开始-->
		<Drawer v-if="isLogin" v-model="queryDrawer.drawerStatus" :closable="false" placement="left" :transfer="true"
			:draggable="true" :scrollable="true" width="50">
			<table id="queryTable">
				<tr>
					<td>ActId</td>
					<td> <Input v-model="queryParams.ps.actId" /></td>
					<td>ClientId</td>
					<td> <Input v-model="queryParams.ps.clientId" /></td>
				</tr>
				<tr>
					<td>name</td>
					<td> <Input v-model="queryParams.ps.name" /></td>
					<td>desc</td>
					<td> <Input v-model="queryParams.ps.desc" /></td>
				</tr>

				<!-- <tr>
					<td>deviceId</td>
					<td> <Input v-model="queryParams.ps.deviceId" /></td>
					<td></td>
					<td></td>
				</tr> -->

				<tr>
					<td><i-button @click="doQuery()">{{"Query"|i18n}}</i-button></td>
					<td></td>
				</tr>
			</table>
		</Drawer><!-- 数据查询结束-->

        <Drawer ref="asInfo" v-model="opSelDrawer.drawerStatus" :closable="false" placement="right":transfer="false"
         	:styles="opSelDrawer.drawerBtnStyle" :draggable="true" :scrollable="true" 
         	width="50" :mask-closable="false" :mask="false" :z-index="99999"
         	style="z-index: 33333;">
         	<DeviceFunCmdList :selMode="true" @onClose="opSelDrawer.drawerStatus=false"  @onSeletEvent="onOpSelectEvent"></DeviceFunCmdList>
        </Drawer>
		
		<!--  设备服务列表 -->
		<Drawer ref="devSrvListInfo" v-model="devSrvListInfoDrawer.drawerStatus" :closable="false" placement="right" :transfer="true"
		        :draggable="true" :scrollable="true" width="80" :mask-closable="true" :mask="true" :z-index="1">
			<DeviceSrvList ref="devSrvListInfoPanel" :from="true"></DeviceSrvList>
		</Drawer>
		
		<!-- 改名对话框 -->
		<el-dialog
		  title="按钮标题"
		  :visible.sync="showUpdateLabel"
		  width="30%"
		  :before-close="cancelUpdateLabel"
		  :close-on-click-modal="false"
		  :modal="false"
		>
		  <!-- 输入框 -->
		  <el-input
			v-model="labelParam.label"
			placeholder="请输入按钮标题"
			clearable
		  ></el-input>
	
		  <!-- 底部按钮 -->
		  <span slot="footer" class="dialog-footer">
			<el-button @click="cancelUpdateLabel()">取 消</el-button>
			<el-button type="primary" @click="showUpdateLabel=false">确 定</el-button>
		  </span>
		</el-dialog>
		



	</div>
</template>

<script>
	 import DeviceSrvList from "./DeviceSrvList.vue"
	import DeviceFunCmdList from "./DeviceFunCmdList.vue"
	const cid = 'JDeviceSrvItem'

	export default {
		name: cid,
		components: {DeviceFunCmdList,DeviceSrvList},
		data() {
			return {
				errorMsg: '',
				isLogin: false,
				tempList: [],

				boardTypes: [],// this.$jm.IOT.JM_BOARDS,
				
				tempsTypesMap : [],// [{key:1, val: "继电器"}, {key:2, val:"磁保持继电器"}, {key:3, val:"MQ传感器"}] ,
				temps: [],

				queryParams: {
					size: 10,
					curPage: 1,
					ps: {}
				},
				totalNum: 0,

				model: 3,
				selProductId: "",
				temp: {},

				prdMap: {},
				allDeviceMap: {},
				
				opSelectType:0, //1:先择查询接口， 2：选择操作接口
				
				oldLabelVal:null,
				showUpdateLabel:false,
				labelParam:{},
				
				selOpType:0,
				selOpIns:null,
				
				deviceDrawer: {
					drawerStatus: false,
					drawerBtnStyle: {
						left: '0px',
						zindex: 1000
					},
				},

				queryDrawer: {
					drawerStatus: false,
					drawerBtnStyle: {
						left: '0px',
						zindex: 1000
					},
				},
				
				opSelDrawer:{
					drawerStatus: false,
					drawerBtnStyle: {
						left: '0px',
						zindex: 9999
					},
				},
				
				devSrvListInfoDrawer:{
					drawerStatus:false,
					drawerBtnStyle:{left:'0px',zindex:1},
				},

			}
		},

		methods: {
			
			serviceList(srvItem){
				this.$refs.devSrvListInfoPanel.loadDataBySrvItem(srvItem,this.prdMap,this.allDeviceMap)
				this.devSrvListInfoDrawer.drawerBtnStyle.zindex=1
				this.devSrvListInfoDrawer.drawerStatus = true;
			},
			
			getSrvTypeName(type){
				let t = this.tempsTypesMap.find(e=>e.attr0==type)
				return t?t.name:""
			},
			
			cancelUpdateLabel(){
				this.showUpdateLabel = false
				this.labelParam.label = this.oldLabelVal
			},
			
			updateLabel(c) {
				this.labelParam = c
				this.oldLabelVal = c.label
				this.showUpdateLabel=true
			},
			
			addParam(){
				this.selectOp(2,null)
			},
			
			clearQryOp(type, ins){
				if(type == 1) {
					this.temp.dataQryDefId = 0
					this.temp.dataQryName = ""
				}else if(type == 3) {
					ins.qryOpId = null
					ins.qryName = null
				}
			},
			
			selectOp(type, ins){
				this.selOpIns = ins
				this.opSelectType = type
				this.opSelDrawer.drawerStatus = true;
			},
			
			viewParam(c){
				
			},
			
			deleteParam(opId) {
				let idx = this.temp.ops.findIndex(e=>e.id==opId)
				if(!idx>=0){
					this.temp.ops.splice(idx,1)
				}
			},
			
			selectTemplate(v) {
				let b = this.temps.find(e=>e.id==v)
				if(b) this.temp.templateName = b.val
				console.log("selectTemplate", b, v, this.temps)
			},
			
			selectBoardType(v) {
				let b = this.boardTypes.find(e=>e.attr0==v)
				if(b) this.temp.boardName=b.name
				console.log("selectBoardType",b,v,this.boardTypes)
			},

            onOpSelectEvent(op){
				this.opSelDrawer.drawerStatus = false
				console.log("op",op)
				
				if(this.opSelectType == 1) {
					//主数据查询
					this.temp.dataQryDefId = op.id
					this.temp.dataQryName = op.name
					this.temp.qryInterval = 0
				}else if(this.opSelectType == 2) {
					//增加操作
					if(!this.temp.ops) this.temp.ops = []
					let eop = this.temp.ops.find(e=>e.id==op.id)
					if(!eop){
						this.temp.ops.push({opId:op.id, name:op.name})
					}
				}else if(this.opSelectType == 3) {
					//操作状态据查询
					if(this.selOpIns) {
						this.selOpIns.qryOpId = op.id
						this.selOpIns.qryName = op.name
					}
				}
			},
			
           
			
			doQuery() {
				this.queryParams.curPage = 1
				this.refresh()
			},

			openQueryDrawer() {
				this.queryDrawer.drawerStatus = true
				this.queryDrawer.drawerBtnStyle.zindex = 10000
				this.queryDrawer.drawerBtnStyle.left = '0px'
			},

			viewDev(c) {
				this.selProductId = c.productId
				this.model = 3
				this.temp = c
				this.errorMsg = ''
				this.deviceDrawer.drawerStatus = true
			},

			addDev() {
				this.selProductId = ""
				this.model = 2
				this.temp = {
					type: 1
				}
				this.errorMsg = ''
				this.deviceDrawer.drawerStatus = true
			},

			updateDev(c) {
				this.selProductId = c.productId
				this.model = 1
				this.temp = c
				this.errorMsg = ''
				this.deviceDrawer.drawerStatus = true
			},

			doAddOrUpdateParam() {
				if (!this.checkParam(this.temp)) {
					return
				}

				this.temp.productId = parseInt(this.selProductId)

				console.log(this.temp)
				if (this.model == 1) {
					//update
					this.$jr.rpc.invokeByCode(-1550828146, [this.temp])
						.then((resp) => {
							if (resp.code == 0) {
								this.deviceDrawer.drawerStatus = false;
								this.$notify.info({
									title: '提示',
									message: "更新成功"
								});
							} else {
								this.$notify.error({
									title: '错误',
									message: resp.msg || "未知错误"
								});
							}
						}).catch((err) => {
							this.$notify.error({
								title: '错误',
								message: err
							});
						});
				} else if (this.model == 2) {
					//add
					this.$jr.rpc.invokeByCode(1446405030, [this.temp])
						.then((resp) => {
							if (resp.code == 0 && resp.data) {
								this.deviceDrawer.drawerStatus = false
								this.refresh()
								this.$notify.info({
									title: '提示',
									message: "保存存成功"
								});
							} else {
								this.$notify.error({
									title: '错误',
									message: resp.msg || "未知错误"
								});
							}
						}).catch((err) => {
							this.$notify.error({
								title: '错误',
								message: err
							});
						});
				}
			},

			checkParam(d) {
				if (!d.type) {
					this.$notify.error({
						title: '错误',
						message: "缺少模板所属分类"
					});
					return false
				}

				if (!d.name) {
					this.$notify.error({
						title: '错误',
						message: "模板名称不能为空"
					});
					return false
				}

				if (!d.templateId) {
					this.$notify.error({
						title: '错误',
						message: "模板数据不能为空"
					});
					return false
				}
				
				if (d.qryInterval && parseInt(d.qryInterval) < 3) {
					this.$notify.error({
						title: '错误',
						message: "查询间隔必须大于或等于3秒"
					});
					return false
				}
				
				return true
			},

			deleteDev(defId) {
				//delFunDef
				this.$jr.rpc.invokeByCode(-927801700, [defId])
					.then((resp) => {
						if (resp.code == 0) {
							this.refresh()
						} else {
							this.$notify.error({
								title: '错误',
								message: resp.msg
							});
						}
					}).catch((err) => {
						this.$notify.error({
							title: '错误',
							message: resp.msg
						});
					});
			},

			curPageChange(curPage) {
				this.queryParams.curPage = curPage
				this.refresh();
			},

			pageSizeChange(pageSize) {
				this.queryParams.size = pageSize
				this.queryParams.curPage = 1
				this.refresh();
			},

			async getTempList() {
				let r = await this.$jr.rpc.invokeByCode(-396321086, [])
				
				if (r.code != 0) {
					this.$notify.error({
						title: '错误',
						message: JSON.stringify(r)
					});
					return
				}

				if (!r.data || r.data.length == 0) {
					map["0"] = "" //防止重复加载无效请求
					console.log("无主设备数据")
					return
				}

				this.temps = []
				for(let i in r.data) {
				    this.temps.push({id:parseInt(i), val:r.data[i]})	
				}
                console.log("template list", this.temps)
			},

			refresh() {
				let self = this;
				this.isLogin = this.$jr.auth.isLogin();
				if (this.isLogin) {
					let params = this.getQueryConditions();

					let self = this;
					this.$jr.rpc.invokeByCode(2025746942, [params])
						.then((resp) => {
							if (resp.code == 0) {
								self.tempList = resp.data;
								self.tempList.forEach(e => {
									if (!e.productId) {
										e.productId = ""
									} else {
										e.productId = e.productId + ""
									}
								})
								console.log(self.tempList)
								self.totalNum = resp.total;
								//self.queryParams.curPage = 1;
							} else {
								this.$notify.error({
									title: '错误',
									message: resp.msg
								});
							}
						}).catch((err) => {
							this.$notify.error({
								title: '错误',
								message: JSON.stringify(err)
							});
						});
				} else {
					self.tempList = [];
				}
			},

			getQueryConditions() {
				return this.queryParams;
			},
		},

		async mounted() {
			this.$el.style.minHeight = (document.body.clientHeight - 67) + 'px';
			this.$jr.auth.addActListener(this.refresh);
			this.prdMap = await this.$jm.getPrdMap();
			this.allDeviceMap = await this.$jm.getDevices()
			await this.getTempList();
			
			let r = await this.$jr.api.getDataType("iot_board_type")
			console.log("iot_board_type",r)
			if(r.code == 0) {
				this.boardTypes = r.data
				if(this.boardTypes && this.boardTypes.length) {
					this.boardTypes.forEach(e=>e.attr0=parseInt(e.attr0))
				}
			}
			
			let rs = await this.$jr.api.getDataType("device_srv_type")
			console.log("device_srv_type",rs)
			if(rs.code == 0) {
				this.tempsTypesMap = rs.data
				this.tempsTypesMap.forEach(e=>e.attr0=parseInt(e.attr0))
			}

			this.refresh();
			let self = this;
			this.$bus.$emit("editorOpen", {
				"editorId": cid,
				"menus": [{
						name: "REFRESH",
						label: "Refresh",
						icon: "ios-cog",
						call: self.refresh
					},
					{
						name: "Add",
						label: "Add",
						icon: "ios-cog",
						call: self.addDev
					},
				]
			});

			let ec = function() {
				this.$jr.auth.removeActListener(cid);
				this.$off('editorClosed', ec);
			}

			this.$bus.$on('editorClosed', ec);
		},

		beforeDestroy() {
			this.$jr.auth.removeActListener(cid);
		},

	}
</script>

<style scoped>
	.JDeviceList {
		min-height: 500px;
	}

	.descCol {
		overflow: hidden;
		text-overflow: ellipsis;
		flex-wrap: nowrap;
	}

	.title {
		font-weight: bold;
	}

	
</style>